<template>
    <div>
        <v-container fluid>
        <breadcrumbs :breadcrumbs="breadcrumbs"/>
        <v-row justify="">
            <v-col cols="4">
                <v-card class="rounded-lg shadow flex d-flex flex-column">
                    <v-card-title>Interactions</v-card-title>
                    <v-card-text class="flex pa-0">
                        <v-simple-table v-if="(!isLoading && (stats != null))">
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th>{{ $t('field.action') }}</th>
                                    <th>{{ $t('field.numberAction') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(value, key) in stats" :key="key">
                                    
                                    <td class="font-weight-medium"><span >{{ key }}</span></td>
                                    <td>{{ value }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <NoResult v-else size="180" />
                    </v-card-text>
                </v-card>
            </v-col>


            <v-col cols="8">
                <v-card class="rounded-lg shadow flex d-flex flex-column">
                    <v-card-title>Interactions par wilaya</v-card-title>
                    <v-card-text class="flex pa-0">
                        <v-simple-table v-if="(!isLoading && (stats != null))">
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th>-</th>
                                    <th v-for="(value, key) in stats" :key="key">
                                        {{ key }}
                                    </th>
                                    <th>TOTAL</th>

                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(value, key) in details" :key="'tr'+key">
                                    
                                    <td :key="('tr-'+key+'-0')">
                                        {{ setTotal(0) }}
                                        {{ setCurrentKey(key) }}
                                        {{ key }}
                                    </td>
                                    <td v-for="(value, key) in stats" :key="('tr-'+key+'-'+value)">
                                        {{ value = details[current_key][key] != null ? details[current_key][key].length : 0}}
                                        {{ addTotal(value) }}
                                    </td>
                                    <td>
                                    {{ total }}

                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <NoResult v-else size="180" />
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>
        </v-container>

            
    </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default{
    data() {
        return {
            isLoading: false,
            data: null,
            stats: null,
            total: 0,
            current_key:null
        }
    },
    methods: {
        getStatistique(){
            this.$Progress.start()
            this.isLoading = true
            HTTP.get('/publicities/tracks/' + this.$route.params.id).then(res => {
                // console.log(res.data.data)
                this.details = res.data.data.details
                this.stats = res.data.data.stats
                
                this.$Progress.finish()
                this.isLoading = false
            }).catch(err => {
                this.$Progress.fail()
                this.$errorMessage = this.$i18n.t('message.catchError')
                this.isLoading = false
                console.log(err)
            })
        },
        setTotal(value){
            this.total = value
        },
        addTotal(value){
            this.total += value
        },
        setCurrentKey(value){
            this.current_key = value;
        }
    },
    created(){
        this.getStatistique();
    },
    computed: {
        breadcrumbs() {
        return [
            {to: '/publicities', text: 'breadcrumb.publicities'},
        ]
        },
    }
}
</script>
